<template>
    <div class="pt-4">
        <page-section v-if="$vuetify.breakpoint.forPC" class="pa-0 ma-0" style="height:50px">
            <v-btn fab depressed style="display:block" class="grey lighten-4 mx-auto" width="100" height="100" @click="$vuetify.goTo(0)">
                <img src="@/assets/web-figures/back-to-top-button.png" />
            </v-btn>
        </page-section>

        <page-section class="grey lighten-4">
            <v-row>
                <v-col :cols="$vuetify.breakpoint.forPC ? 6 : 12">
                    <div id="tutti">
                        <img id="tutti-logo" src="@/assets/web-figures/logo.png" />
                        <v-divider class="divider mb-6"></v-divider>
                        <div id="tutti-menu" class="d-flex">
                            <div>
                                <p><a class="link" href="/">トップ</a></p>
                                <p><a class="link" href="/features">機能</a></p>
                                <p><a class="link" href="/prices">料金・導入フロー</a></p>
                                <p><a class="link" href="https://www.iflab.co.jp/" target="_blank">会社概要<v-icon small right>mdi-open-in-new</v-icon></a></p>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.forPC ? 6 : 12">
                    <div id="iflab">
                        <img id="iflab-logo" src="@/assets/web-figures/iflab-logo.png" />
                        <v-divider class="divider mb-6"></v-divider>
                        <div id="iflab-menu" class="d-flex">
                            <div>
                                <p>株式会社知能フレームワーク研究所（ifLab）</p>
                                <p>〒143-0015</p>
                                <p>東京都大田区大森西2-22-19</p>
                                <p>info@iflab.co.jp</p>
                                <p>080-1987-9784</p>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <div id="copyright">
                © {{ currentYear }} Intelligent Framework Lab Inc.
            </div>
        </page-section>
    </div>
</template>
<script>
import PageSection from './PageSection.vue'

export default {
    components: { PageSection },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    }
}
</script>
<style scoped>
#tutti, #iflab {
    width: 90%;
}
#tutti-logo, #iflab-logo {
    height: 50px;
}
.divider {
    border-color: grey!important;
}
#tutti-menu > div {
    width: 50%;
}
#tutti-menu > div > p {
    font-size: 14px;
}
#iflab-menu > div {
    width: 100%;
}
#iflab-menu > div > p {
    font-size: 12px;
    margin-bottom: 8px;
}
#copyright {
    text-align: center;
    margin-top: 50px;
}
.link {
    color: black;
    text-decoration: none;
}
</style>
